import React from "react";
import { useHistory } from 'react-router-dom';

import { Button, Card, CardBody, CardTitle } from "reactstrap";
import './HojokinDetailServiceEntry.css'

import issue1 from '../assets/issue1.png';

const HojokinDetailServiceEntry = () => {

  const history = useHistory();

  return (
    <div className="text-center mx-auto pb-3 service-entry-banner mt-4">
      <Card className="my-1 detail-card">
        <CardBody>
          <div class="row">
            {/* 案内文とボタン */}
            <div class="col-sm d-flex align-items-center">
              <div class="mx-auto">
                <CardTitle>
                  <h5 class="mb-0">
                  公募中の補助金を
                  <br class="d-none d-md-inline"/>
                  <span class="detail-overlook">&nbsp;見落とさない&nbsp;</span>
                  ためには？
                  </h5>
                  <div class="mt-2"><small class="text-muted detail-caption">
                  国や市区町村の補助金をまるっと確認。
                  </small></div>
                </CardTitle>
                <Button
                  id="register-button-hd"
                  className="btn btn-primary btn-lg mt-2 mb-1 p-3"
                  onClick={() => {history.push('/');window.scrollTo(0, 0);}}
                >
                  詳細はこちらをチェック！
                </Button>
              </div>
            </div>

            {/* イメージ */}
            <div class="col-sm">
              <img className="inner-fit detail-image pt-3" src={issue1} alt="使える補助金・助成金を手軽に知りたい方へ。自社にマッチする情報を新着レコメンド！" />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default HojokinDetailServiceEntry;
