import React, {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Table } from 'reactstrap';
import { useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import './FavHojokinsTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from "@auth0/auth0-react";
import { CybozuLabs } from "../utils/md5";

const RecommendHojokinsTable = () => {

  // URL Path
  let { user_id_hash } = useParams();

  // auth0 ユーザー情報を取得
  const { user, getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();

  // ログインユーザーと取得ユーザーIDハッシュの一致確認
  const is_same_user = CybozuLabs.MD5.calc(user.email) == user_id_hash;

  // APIエンドポイント
  const api_url = window.location.hostname === 'localhost' ? 'http://localhost:5000/api/' : (`https://${window.location.hostname}/api/`);
  const hojokin_fav_api_url = `${api_url}hojokin-fav/`;
  const hojokin_recommend_api_url = `${api_url}hojokin-recommend/`;
  const hojokin_detail_api_url = `${api_url}hojokin/`;
  const remind_config_api_url = `${api_url}remind-config`

  // 状態変数
  const LOADING_STR = "読込中...";
  const [update, setUpdate] = useState(false);
  const [tableDict, setTableDict] = useState([{
    id: LOADING_STR,
    detail: LOADING_STR,
    url: LOADING_STR,
    abstract: LOADING_STR,
    region: LOADING_STR,
    termFrom: LOADING_STR,
    termEnd: LOADING_STR,
    updatedAt: LOADING_STR,
    termContinue: null,
    termBudget: null,
    budgetMinistry: "",
  }]);

  // 日付文字列の作成関数
  // - "2022-07-14" => "7月14日"
  // - "2023-07-14" => "2023年7月14日"
  const mk_term_end_str = ((term_end) => {
    if(term_end==LOADING_STR) {
      return LOADING_STR
    }
    let term_end_ymd = term_end.split('-').map((e)=>~~e);
    let delta_days = Math.ceil((new Date(term_end)-new Date()) / (1000 * 3600 * 24));
    let term_end_str = (delta_days>=364) ?
      `${term_end_ymd[0]}年${term_end_ymd[1]}月${term_end_ymd[2]}日` :
      `${term_end_ymd[1]}月${term_end_ymd[2]}日`;
    return term_end_str
  });

  // 設定値の取得
  useEffect(() => {
    // 値の取得
    $.ajax({
      url: `${hojokin_recommend_api_url}${user_id_hash}/list`,
      type: 'GET',
      crossDomain: true,
      dataType: 'json',
      success: (result, textStatus, xhr) => {
        try {
          let result_body = result;
          console.log(result_body);
          if(result_body.status === "OK") {
            let _tableDict = tableDict;
            _tableDict = result_body.value; // 取得結果で上書き
            setTableDict(_tableDict);
            setUpdate(update ? false : true); // 強制再レンダリング
          }
          else {
            console.log("response status NG");
          }
        } catch(error) {
          console.log("response parse error");
        }
      },
      error: (xhr, textStatus, error) => {
        console.log("server error");
      }
    });
  }, [setTableDict]);

  const helmetElm = <Helmet
    title="おすすめ補助金一覧｜補助金おすすめ.com"
    link={[
      {
        rel:"canonical",
        href:(window.location.origin + window.location.pathname)
      }
    ]}
    meta={[
      {
        name:"google-site-verification",
        content:"F_UIiaqRlypk1_5WoPiHdFdneMSpYvOlYLmWBkyXmIU"
      }, {
        name:"description",
        content:"公募中のおすすめ補助金・助成金の一覧を表示します。"
      }
    ]}
  />

  // リマインド設定追加
  token.then(token => {
    $.ajax({
      url: remind_config_api_url,
      type: 'POST',
      crossDomain: true,
      data: JSON.stringify({
        'id':user.email
      }),
      contentType: 'application/json',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      success: (result_body, textStatus, xhr) => {
        try {
          if(result_body.status === "OK") {
            console.log("remind setting OK.");
          }
          else {
            console.log("remind setting NG.");
          }
        } catch(error) {
          console.log(`remind setting NG. detail: ${error}`);
        }
      },
      error: (xhr, textStatus, error) => {
        console.log(`remind setting server error. detail: ${error}`);
      }
    });
  });

  // テーブル情報の描画
  if(is_same_user) {
    return (
      <HelmetProvider>
        {helmetElm}
        <div className="text-left hojokin-table mt-3 mx-auto px-2">
          <h1 className="mb-4">
            おすすめ補助金・助成金一覧
          </h1>
          { (tableDict.length==0) && (<div>おすすめの補助金・助成金はありませんでした。</div>)}
          { (tableDict.length>0) && (
            <div>
              <div>
                <p>&hearts;追加すると、申請期限に近づくとリマインド通知します。<br class="d-none d-md-inline"/>
                気になる補助金・助成金は&hearts;に追加して、忘れないようにしましょう。</p>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th width="20%">公募件名</th>
                    <th width="35%">概要</th>
                    <th width="15%">地域</th>
                    <th width="16%">申請期限</th>
                    <th width="14%"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableDict.map((e)=> {
                    return (
                      <tr>
                          <td class='hojokin_title'>
                            <a href={`${hojokin_detail_api_url}${e['id']}/${user_id_hash}`} target="_blank" rel="noopener noreferrer">{e['detail']}</a>
                          </td>
                          <td class='hojokin_abstract'>{e['abstract']}</td>
                          <td class='hojokin_region'>
                            <div>{e['budgetPref']}</div>
                            {(e['budgetMinistry']!=="") && (<div class='hojokin_budgetMinistry'>{e['budgetMinistry']}</div>)}
                          </td>
                          <td class='hojokin_termEnd'>
                            <div>〜<b>{mk_term_end_str(e['termEnd'])}</b></div>
                            {(e['termContinue']==="True") && (<div class='hojokin_termContinue'>※随時募集</div>)}
                            {(e['termBudget']==="True") && (<div class='hojokin_termBudget'>※予算なくなり次第終了</div>)}
                          </td>
                          <td class='hojokin_fav' align="center">{
                            (e['id']===LOADING_STR) ?
                              "-" :
                              <a href={`${hojokin_fav_api_url}${e['id']}/${user_id_hash}/create?redirect=true`}  target="_blank" rel="noopener noreferrer">♥追加<FontAwesomeIcon icon={['fas', 'external-link-alt']} /></a>
                          }</td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </HelmetProvider>
    );
  } else {
    return (
      <HelmetProvider>
        {helmetElm}
        <div className="text-left hojokin-table mt-3 mx-auto px-2">
          <h1 className="mb-4">
            おすすめ補助金・助成金一覧
          </h1>
          <div>
            <div>ログインユーザーと、参照しようとしているユーザーが一致しません。</div>
            <div>一度ログアウトしてから参照しようとしているユーザーとしてログインしてください。</div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default RecommendHojokinsTable;