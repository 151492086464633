import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import EmailVerify from "./views/EmailVerify";
import Home from "./views/Home";
import RecommendConfig from "./views/RecommendConfig"
import Profile from "./views/Profile";
import HojokinDetail from "./views/HojokinDetail";
import FavoriteHojokins from "./views/FavoriteHojokins";
import RecommendHojokins from './views/RecommendHojokins'

// auth0
import { useAuth0 } from "@auth0/auth0-react";

// utils
import { CybozuLabs } from "./utils/md5";
import history from "./utils/history";
import Auth from './utils/Auth';

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error, user, loginWithRedirect } = useAuth0();
  const error_email_verify = 'Please verify your email before logging in.';
  const user_id_hash = user ? CybozuLabs.MD5.calc(user.email) : 'none';

  if (error) {
    return (
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            {(error.message===error_email_verify) && (
              <Route exact component={EmailVerify} />
            )}
            {(error.message!==error_email_verify) && (
              <div className="pl-5">[エラー] {error.message}</div>
            )}
          </Container>
          <Footer />
        </div>
      </Router>
    )
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <div className="flex-grow-1 mt-5">
          <Switch>
            <Route
              exact path="/login"
              component={() => loginWithRedirect()} />
            <Route exact path="/use-case" component={Home} />
            <Route path="/hojokin-detail/:hojokin_id" component={HojokinDetail} />
            <Auth>
              <Switch>
                <Route exact path="/" component={RecommendConfig} />
                <Route exact path="/recommend-config" component={RecommendConfig} />
                <Route path="/profile" component={Profile} />
                <Route path="/hojokin-fav/:user_id_hash" component={FavoriteHojokins} />
                <Redirect path="/hojokin-fav" to={"/hojokin-fav/"+user_id_hash} />
                <Route path="/hojokin-recommend/:user_id_hash" component={RecommendHojokins} />
                <Redirect path="/hojokin-recommend" to={"/hojokin-recommend/"+user_id_hash} />
                <Route render={() =>
                  <div class="d-flex align-items-center h-100">
                    <p class="text-center mx-auto lead">
                      ページが見つかりませんでした。<br/>
                      <a href='/'>トップに戻る</a>
                    </p>
                  </div>}
                />
              </Switch>
            </Auth>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
