import React, {useState} from "react";
import "./FAQ.css"
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FAQ = () => {

  // 「よくあるご質問」におけるタイトルと説明
  const title_details = [
    [
      "自社で「補助金おすすめ.com」を使う方がよいのはなぜですか？",
      "「補助金おすすめ.com」は、国（省庁）や都道府県、市区町村が公募している補助金・助成金を知るための新しいツールです。 "+
      "メールさえ使える環境にあれば、自動的に新着の補助金・助成金情報をキャッチアップできます。 "+
      "これまでは、日々の業務の中で、例えば経産省や東京都、福岡市といった各サイトの公募情報を巡回する必要がありましたが、これら手間を省くことができ、公募情報の見落としを軽減できます。"
    ],
    [
      "「補助金おすすめ.com」を効果的に使うにはどうすればよいですか？",
      "「補助金おすすめ.com」は、サービス登録したメールアドレス宛に、新着の補助金・助成金情報をメール送信します。 "+
      "登録者ご自身のみでご利用の場合は、普段業務で利用しているメールアドレスでサービス登録すると良いでしょう。 "+
      "また、社内グループなど複数名で利用する場合は、メーリングリストのメールアドレスでサービス登録すると良いでしょう。"+
      "\n\n"+
      "その他、SlackやTeamsなどのチャットツールをご利用の場合、チャンネルやルームでメール受信できるように設定するとより便利でしょう。 "+
      "これらについての詳細は、SlackやTeamsのヘルプをご確認ください。"
    ],
    [
      "「補助金おすすめ.com」は無料ですか？",
      "はい、完全に無料でご利用いただけます。 "+
      "当社が将来的に有料サービスを追加展開する場合においても、既存ユーザーに対して自動的に利用料金が発生することはありません。"
    ],
    [
      "「補助金おすすめ.com」は安全ですか？",
      "はい。サービスご登録者ご本人の同意を得ることなしに、登録情報を他社に共有するといったことはありません。 "+
      "詳細は利用規約およびプライバシーポリシーをご確認ください。"
    ]
  ];

  const [toggleHandler, setToggleHandler] = useState(
    title_details.map(() => false)
  );

  const [update,setUpdata]=useState(false);

  // テキスト内の\nを<br/>オブジェクトに置換する
  const detail_component = (msg) => {
    const texts = msg.split("\n").map((item, index) => {
      return (
        <React.Fragment key={index}>{item}<br /></React.Fragment>
      );
    });
    return <div>{texts}</div>;
  }

  return (
    <div className="text-center my-3 pb-4">
      <div className="container">

        {/* 利用のイメージ */}
        <div class="container p-0">
          <h2 className="mb-4 mt-5">よくあるご質問</h2>
          <div class="row">
            <div class="col-sm mb-5">
              {
                toggleHandler.map((isShow, index) => {
                  return (
                    <Card className="faq-card border-light">
                      <CardHeader
                        className="faq-card-header py-4 text-left"
                        onClick={() => {
                          toggleHandler[index] = !toggleHandler[index];
                          setToggleHandler(toggleHandler);
                          setUpdata(update ? false : true); // 強制再レンダリング
                        }}
                      >
                        <span className="font-weight-bold">{title_details[index][0]}</span>
                        <span className="float-right"><FontAwesomeIcon icon={toggleHandler[index] ? ['fas', 'angle-up'] : ['fas', 'angle-down']} /></span>
                      </CardHeader>
                      <Collapse isOpen={toggleHandler[index]}>
                          <CardBody className="text-left">
                              {detail_component(title_details[index][1])}
                          </CardBody>
                      </Collapse>
                    </Card>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;