import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import email_verify_image from '../assets/email_verify.png'

const EmailVerify = () => {
  // auth0 ユーザー情報を取得
  const { user } = useAuth0();

  return (
    <div className="next-steps my-5 email-verify mx-auto px-0">
      <div className="text-center">
        <h2 className="my-5 text-center">仮登録完了</h2>
        <p className="lead text-center pl-0 pr-0">
          入力いただいたメールアドレス宛てに、本登録に向けてのメールを送信しました。<br class="d-none d-lg-inline"/>
          メール内のリンクをクリックし、本登録を行ってください。
        </p>
      </div>
      <img src={email_verify_image} className="inner-fit d-block mx-auto" />
    </div>
  );
};

export default EmailVerify;