import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Home from '../views/Home'

const Auth = (props) => {

    // ログインしているか取得
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        // ログインしてるとき（そのまま表示）
        return props.children;
    } else {
        // ログインしてないとき（Homeを表示）
        return <Home/>
    }
};

export default Auth;