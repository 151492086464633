import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import FavHojokinsTable from "../components/FavHojokinsTable";

const FavoriteHojokins = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <Fragment>
      <div className="mb-5">
        <FavHojokinsTable />
      </div>
    </Fragment>
  );
};

export default FavoriteHojokins;
