import React, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RecommendSettingForm.css';
import $ from 'jquery';
import { useAuth0 } from "@auth0/auth0-react";

const RecommendSettingForm = () => {

  // auth0 ユーザー情報を取得
  const { user, getAccessTokenSilently } = useAuth0();
  const token = getAccessTokenSilently();

  // 状態変数
  const [saveButtonName, setSaveButtonName] = useState("設定を保存");
  const [testMailButtonName, setTestMailButtonName] = useState("今すぐ受信する");
  const [update,setUpdata]=useState(false);
  const [formDict, setFormDict] = useState({
    name: "",
    companyName: "",
    companyLocationPref: "",
    companyLocationCity: "",
    industry: "",
    capital: "",
    employees: "",
    keyword: "",
    mailFreq: "毎日"
  });

  // APIエンドポイント
  const api_url = window.location.hostname === 'localhost' ? 'http://localhost:5000/api/' : ('https://'+window.location.hostname+'/api/');
  const recommend_config_api_url = api_url + 'recommend-config';
  const recommend_mail_api_url = api_url + 'recommend-mail';

  // メール送信
  const handleTestMailSend = () => {

    // 送信データの作成
    let request_body = {
      "id": user.email,
      "is_test_mail": 'true'
    };

    // テスト送信
    token.then(aToken => {
      setTestMailButtonName("送信しました");
      $.ajax({
        url: recommend_mail_api_url,
        type: 'POST',
        crossDomain: true,
        data: JSON.stringify(request_body),
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${aToken}`,
        },
        success: (result_body, textStatus, xhr) => {
          try {
            if(result_body.status === "OK" && result_body.message === "no hojokins found.") {
              console.log("いま使える補助金が見つかりませんでした");
            } else if (result_body.status === "OK" && result_body.message === "mail sent.") {
              console.log("メール送信を完了しました");
            } else {
              console.log("メール送信に失敗しました");
            }
          } catch(error) {
            console.log("メール送信に失敗しました");
            console.log(error);
          }
        },
        error: (xhr, textStatus, error) => {
          console.log("メール送信に失敗（サーバエラー）しました");
          console.log(error);
        }
      });
    });
  };

  // 設定を保存
  const _handleSubmit = (values) => {

    // 表記変更
    setSaveButtonName("保存中…")

    // 送信データの作成
    let request_body = values;
    values["id"] = user.email;

    // 保存
    token.then(token => {
      $.ajax({
        url: recommend_config_api_url,
        type: 'POST',
        crossDomain: true,
        data: JSON.stringify(request_body),
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        success: (result_body, textStatus, xhr) => {
          try {
            if(result_body.status === "OK") {
              setSaveButtonName("保存しました");
            }
            else {
              setSaveButtonName("保存に失敗");
            }
          } catch(error) {
            setSaveButtonName("保存に失敗");
            console.log(error);
          }
        },
        error: (xhr, textStatus, error) => {
          setSaveButtonName("保存に失敗（サーバエラー）");
          console.log(error);
        }
      });
    });
  };

  // 設定値の取得
  useEffect(() => {
    // 値の取得
    token.then(aToken => {
      $.ajax({
        url: recommend_config_api_url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${aToken}`,
        },
        crossDomain: true,
        data: {"id": user.email},
        dataType: 'json',
        success: (result_body, textStatus, xhr) => {
          try {
            if(result_body.status === "OK") {
              let _formDict = formik.values;
              for(let _key in formik.values) {
                let _value = result_body.value[_key];
                if(_value) {
                  _formDict[_key] = _value;
                }
              }
              setFormDict(_formDict);
              setUpdata(update ? false : true); // 強制再レンダリング
            }
            else {
              console.log("response status NG");
            }
          } catch(error) {
            console.log("response parse error");
          }
        },
        error: (xhr, textStatus, error) => {
          console.log("server error");
        }
      });
    })
  }, [setFormDict]);

  // バリデーションチェックして問題があればそこにジャンプ
  const validation_check = () => {
    try {
      validation_schema.validateSync(formik.values);
      return true;
    } catch (e) {
      let validation_elm = document.getElementsByName("validation_error");
      if(validation_elm.length > 0) {
        let top_position = validation_elm[0].getBoundingClientRect().top;
        window.scrollTo({top: top_position, behavior: 'smooth'});
      } else {
        document.getElementById("name").focus();
      }
      return false;
    }
  };

  // バリデーションチェック
  const required_message_text = '必須項目のため入力ください。';
  const required_message_select = '必須項目のため選択ください。';
  const validation_schema = Yup.object().shape({
    name: Yup.string().required(required_message_text),
    companyName: Yup.string().required(required_message_text),
    companyLocationPref: Yup.string().required(required_message_select),
    companyLocationCity: Yup.string(),
    industry: Yup.string(),
    capital: Yup.string(),
    employees: Yup.string(),
    keyword: Yup.string(),
    mailFreq: Yup.string().required(required_message_text),
  });
  const formik = useFormik({
    initialValues: formDict,
    validationSchema: validation_schema,
    onSubmit: (values) => {
      _handleSubmit(values);
    }
  });

  // いますぐメールを受信
  const just_now_mail = () => {
    const validation = new Promise((resolve, reject) => {
      if(validation_check()){
        resolve();
      }
    }).then((result) => {
      setTestMailButtonName("処理中…");
      formik.handleSubmit();
      window.setTimeout(() => {
        handleTestMailSend();
      }, 3000);
    })
  };

  return (
    <Form
      className="container pb-5 mb-5 recommend-setting-form px-0"
      onSubmit={formik.handleSubmit}
      >
      <FormGroup>
        <Label for="name">名前</Label>
        <Input type="text" name="name" id="name" placeholder="例）鈴木 一郎" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        {formik.touched.name && formik.errors.name ? (
          <div name="validation_error" class="alert alert-danger py-1 mt-1 font-weight-bold" role="alert">{formik.errors.name}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="companyName">会社名</Label>
        <Input type="text" name="companyName" id="companyName" placeholder="例）株式会社ロカリア" value={formik.values.companyName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        {formik.touched.companyName && formik.errors.companyName ? (
          <div name="validation_error" class="alert alert-danger py-1 mt-1 font-weight-bold" role="alert">{formik.errors.companyName}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="companyLocationPref">会社の所在地（都道府県）</Label>
        <Input type="select" name="companyLocationPref" id="companyLocationPref" value={formik.values.companyLocationPref} onChange={formik.handleChange} onBlur={formik.handleBlur} >
          <option value="">選択してください</option>
          <option value="北海道">北海道</option>
          <option value="青森県">青森県</option>
          <option value="岩手県">岩手県</option>
          <option value="宮城県">宮城県</option>
          <option value="秋田県">秋田県</option>
          <option value="山形県">山形県</option>
          <option value="福島県">福島県</option>
          <option value="茨城県">茨城県</option>
          <option value="栃木県">栃木県</option>
          <option value="群馬県">群馬県</option>
          <option value="埼玉県">埼玉県</option>
          <option value="千葉県">千葉県</option>
          <option value="東京都">東京都</option>
          <option value="神奈川県">神奈川県</option>
          <option value="新潟県">新潟県</option>
          <option value="富山県">富山県</option>
          <option value="石川県">石川県</option>
          <option value="福井県">福井県</option>
          <option value="山梨県">山梨県</option>
          <option value="長野県">長野県</option>
          <option value="岐阜県">岐阜県</option>
          <option value="静岡県">静岡県</option>
          <option value="愛知県">愛知県</option>
          <option value="三重県">三重県</option>
          <option value="滋賀県">滋賀県</option>
          <option value="京都府">京都府</option>
          <option value="大阪府">大阪府</option>
          <option value="兵庫県">兵庫県</option>
          <option value="奈良県">奈良県</option>
          <option value="和歌山県">和歌山県</option>
          <option value="鳥取県">鳥取県</option>
          <option value="島根県">島根県</option>
          <option value="岡山県">岡山県</option>
          <option value="広島県">広島県</option>
          <option value="山口県">山口県</option>
          <option value="徳島県">徳島県</option>
          <option value="香川県">香川県</option>
          <option value="愛媛県">愛媛県</option>
          <option value="高知県">高知県</option>
          <option value="福岡県">福岡県</option>
          <option value="佐賀県">佐賀県</option>
          <option value="長崎県">長崎県</option>
          <option value="熊本県">熊本県</option>
          <option value="大分県">大分県</option>
          <option value="宮崎県">宮崎県</option>
          <option value="鹿児島県">鹿児島県</option>
          <option value="沖縄県">沖縄県</option>
        </Input>
        {formik.touched.companyLocationPref && formik.errors.companyLocationPref ? (
          <div name="validation_error" class="alert alert-danger py-1 mt-1 font-weight-bold" role="alert">{formik.errors.companyLocationPref}</div>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label for="companyLocationCity">会社の所在地（市区町村）</Label>
        <Input type="text" name="companyLocationCity" id="companyLocationCity" placeholder="例）福岡市" value={formik.values.companyLocationCity} onChange={formik.handleChange} onBlur={formik.handleBlur} />
      </FormGroup>
      <FormGroup>
        <Label for="capital">資本金</Label>
        <Input type="select" name="capital" id="capital" value={formik.values.capital} onChange={formik.handleChange} onBlur={formik.handleBlur} >
          <option value="">選択してください</option>
          <option value="〜300万円">〜300万円</option>
          <option value="〜1000万円">〜1000万円</option>
          <option value="〜5000万円">〜5000万円</option>
          <option value="〜1億円">〜1億円</option>
          <option value="〜3億円">〜3億円</option>
          <option value="3億円〜">3億円〜</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="employees">従業員数</Label>
        <Input type="select" name="employees" id="employees" value={formik.values.employees} onChange={formik.handleChange} onBlur={formik.handleBlur} >
          <option value="">選択してください</option>
          <option value="〜5人">〜5人</option>
          <option value="〜20人">〜20人</option>
          <option value="〜50人">〜50人</option>
          <option value="〜100人">〜100人</option>
          <option value="〜300人">〜300人</option>
          <option value="301人〜">301人〜</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="industry">業界</Label>
        <Input type="select" name="industry" id="industry" value={formik.values.industry} onChange={formik.handleChange} onBlur={formik.handleBlur} >
          <option value="">選択してください</option>
          <option value="官公庁・団体・福祉">官公庁・団体・福祉</option>
          <option value="スポーツ・趣味娯楽・レジャー">スポーツ・趣味娯楽・レジャー</option>
          <option value="自動車・オートバイ・自転車・ドライブ">自動車・オートバイ・自転車・ドライブ</option>
          <option value="販売・卸">販売・卸</option>
          <option value="サービス業・士業">サービス業・士業</option>
          <option value="出版・印刷">出版・印刷</option>
          <option value="建設・工事">建設・工事</option>
          <option value="農林・水産・鉱業">農林・水産・鉱業</option>
          <option value="製造・加工">製造・加工</option>
          <option value="電気・ガス・通信・放送・新聞">電気・ガス・通信・放送・新聞</option>
          <option value="金融・保険・証券">金融・保険・証券</option>
          <option value="交通・運輸・倉庫">交通・運輸・倉庫</option>
          <option value="医療・薬・保険衛生">医療・薬・保険衛生</option>
          <option value="不動産・賃貸・展示場">不動産・賃貸・展示場</option>
          <option value="学校・趣味教室・図書館">学校・趣味教室・図書館</option>
          <option value="旅行・観光・温泉・旅館・ホテル">旅行・観光・温泉・旅館・ホテル</option>
          <option value="飲食店">飲食店</option>
          <option value="その他">その他</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="keyword">キーワード設定</Label>
        <Input type="textarea" name="keyword" id="keyword" placeholder="例）AI,IoT,機械学習" value={formik.values.keyword} onChange={formik.handleChange} onBlur={formik.handleBlur} />
        <FormText color="muted">
          補助金・助成金を探すにあたり、気になるキーワードを入力ください。
        </FormText>
      </FormGroup>
      <FormGroup tag="fieldset">
        メール通知頻度
        <FormGroup check>
          <Label check>
            <Input type="radio" name="mailFreq" value="毎日" checked={formik.values.mailFreq==="毎日" ? "checked" : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />{' '}
            毎日
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="mailFreq" value="毎週" checked={formik.values.mailFreq==="毎週" ? "checked" : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />{' '}
            毎週（月曜日）
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="mailFreq" value="毎月" checked={formik.values.mailFreq==="毎月" ? "checked" : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />{' '}
            毎月（一日）
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="mailFreq" value="停止" checked={formik.values.mailFreq==="停止" ? "checked" : ""} onChange={formik.handleChange} onBlur={formik.handleBlur} />{' '}
            メール通知を停止する
          </Label>
        </FormGroup>
        <FormText color="muted">
          サービス登録時に入力いただいたメールアドレス宛に、設定いただいた通知頻度にて朝9時ごろにメール通知いたします。
        </FormText>
      </FormGroup>
      <div class="text-center">
        <Button
          className="mt-5 btn-lg p-3" id="save-button"
          type="submit" onClick={() => validation_check()}>{saveButtonName}
        </Button>
        <Button
          className="mt-5 btn-lg p-3 ml-3" id="test-mail-button"
          onClick={() => {just_now_mail()}}>{testMailButtonName}
        </Button>
      </div>
    </Form>
  );
}

export default RecommendSettingForm;