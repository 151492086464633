import React, { Fragment } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import SolutionDigest from "../components/SolutionDigest";
import CanDo from "../components/CanDo";
import QuickStart from "../components/QuickStart";
import FAQ from "../components/FAQ";

const Home = () => (
  <HelmetProvider>
    <Helmet
      title="補助金おすすめ.com｜使える補助金・助成金を新着レコメンド"
      link={[
        {
          rel:"canonical",
          href:(window.location.origin + window.location.pathname)
        }
      ]}
      meta={[
        {
          name:"google-site-verification",
          content:"F_UIiaqRlypk1_5WoPiHdFdneMSpYvOlYLmWBkyXmIU"
        }, {
          name:"description",
          content:"使える補助金を手軽に知りたい方へ、新着レコメンド！メールアドレスのみで簡単サービス登録できます。補助金をおすすめするための受信設定を行うだけ！完全に無料でご利用いただけます。"
        }
      ]}
    />
    <Fragment>
      <SolutionDigest />
      <CanDo />
      <QuickStart />
      <FAQ />
    </Fragment>
  </HelmetProvider>
);

export default Home;
