import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './NavBar.css'

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

import top_logo from "../assets/logo.png";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <div className="nav-container">
      <Navbar expand="md" className="navbar-dark px-0">
        <Container className="navbar-container">
          <div className="d-flex flex-nowrap" >
            <NavbarBrand
              tag={RouterNavLink}
              to="/"
              className="ml-2 mr-2"
            >
              <img className="app-logo inner-fit" src={top_logo} />
            </NavbarBrand>
          </div>
          <NavbarToggler
              onClick={toggle}
              className="mr-3 ml-0 float-right"
          />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-2 mr-auto mt-2 mb-0" navbar>
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                    className="ml-2 mr-0"
                  >
                    通知設定
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/use-case"
                    exact
                    activeClassName="router-link-exact-active"
                    className="ml-2 mr-0"
                  >
                    ご利用イメージ
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem className="ml-2 mr-0">
                  <Button
                    id="slackAppBtn-2"
                    color="primary"
                    className="btn-margin btn"
                    onClick={() => {
                      window.location.href = "https://www.localia.co.jp/service/hojocom/slack-beta/install";
                    }}
                  >
                    Slackアプリβ版
                  </Button>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem className="mb-1">
                  <Button
                    id="slackAppBtn"
                    color="primary"
                    className="btn-margin btn"
                    onClick={() => {
                      window.location.href = "https://www.localia.co.jp/service/hojocom/slack-beta/install";
                    }}
                  >
                    Slackアプリβ版
                  </Button>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin btn"
                    onClick={() => loginWithRedirect()}
                  >
                    ログイン
                  </Button>
                  <Button
                    id="qsSignupBtn"
                    color="primary"
                    className="btn-margin btn ml-2"
                    onClick={() => loginWithRedirect({screen_hint: 'signup'})}
                  >
                    新規登録
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-right">
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> ログアウト
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none mt-0" navbar>
                <NavItem className="mb-0">
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    className="btn"
                    onClick={() => loginWithRedirect({})}
                  >
                    ログイン
                  </Button>
                  <Button
                    id="qsSignupBtn"
                    color="primary"
                    block
                    className="btn"
                    onClick={() => loginWithRedirect({screen_hint: 'signup'})}
                  >
                    新規登録
                  </Button>
                  <Button
                    id="slackAppBtn"
                    color="primary"
                    block
                    className="btn"
                    onClick={() => {
                      window.location.href = "https://www.localia.co.jp/service/hojocom/slack-beta/install";
                    }}
                  >
                    Slackアプリβ版
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none mt-0 py-2 pl-4 pr-2 justify-content-between"
                navbar
                // style={{ minHeight: 170 }}
              >
                <NavItem className="mb-1">
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-1"
                      width="40"
                    />
                    <p className="d-inline-block">{user.name}</p>
                  </span>
                </NavItem>
                <NavItem className="mb-1">
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    ログアウト
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
