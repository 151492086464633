import React, { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import HojokinTable from "../components/HojokinTable";
import HojokinDetailServiceEntry from "../components/HojokinDetailServiceEntry";

const HojokinDetail = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <Fragment>
      {isAuthenticated && (
        <HojokinTable className="mb-5" />
      )}
      {!isAuthenticated && (
        <div className="mb-5">
          <HojokinTable />
          <HojokinDetailServiceEntry />
        </div>
      )}
    </Fragment>
  );
};

export default HojokinDetail;
