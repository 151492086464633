import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
    <HelmetProvider>
      <Helmet
          link={[
            {
              rel:"canonical",
              href:(window.location.origin + window.location.pathname)
            }
          ]}
          meta={[
            {
              name:"google-site-verification",
              content:"F_UIiaqRlypk1_5WoPiHdFdneMSpYvOlYLmWBkyXmIU"
            }, {
              name:"description",
              content:"使える補助金を手軽に知りたい方へ、新着レコメンド！メールアドレスのみで簡単サービス登録できます。補助金をおすすめするための受信設定を行うだけ！完全に無料でご利用いただけます。"
            }
          ]}
        />
        <App />
      </HelmetProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
