import React from "react";
import './Footer.css';
import footer_logo from "../assets/logo.png";

const Footer = () => (
  <footer>
    <div class="container">
      <img className="mt-4 mb-3 app-logo inner-fit px-3" src={footer_logo} />
      <div class="footer-links text-center mb-0">
        <span><a class="ml-3 mr-3" href="https://www.localia.co.jp/">運営会社</a></span>
        <span><a class="mr-3" href="https://www.localia.co.jp/terms/hojokin-osusume">利用規約</a></span>
        <span><a class="mr-3" href="https://www.localia.co.jp/privacy">プライバシーポリシー</a></span>
        <span><a class="mr-3" href="https://www.localia.co.jp/form">お問い合わせ</a></span>
      </div>
      <div class="container text-center">
        <p class="mb-0 py-2">&copy; 2021-{(new Date()).getFullYear()} Localia Inc. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
