import React, {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Table } from 'reactstrap';
import { useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import './HojokinTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HojokinTable = () => {

  // URL Path
  let { hojokin_id } = useParams();

  // APIエンドポイント
  const api_url = window.location.hostname === 'localhost' ? 'http://localhost:5000/api/' : ('https://'+window.location.hostname+'/api/');
  const hojokin_detail_api_url = api_url + 'hojokin-detail/' + hojokin_id;

  // 状態変数
  const LOADING_STR = "読込中...";
  const [update, setUpdate] = useState(false);
  const [tableDict, setTableDict] = useState({
    id: LOADING_STR,
    detail: LOADING_STR,
    url: LOADING_STR,
    abstract: LOADING_STR,
    region: LOADING_STR,
    termFrom: LOADING_STR,
    termEnd: LOADING_STR,
    updatedAt: LOADING_STR,
    termContinue: null,
    termBudget: null,
  });

  // 設定値の取得
  useEffect(() => {
    // 値の取得
    $.ajax({
      url: hojokin_detail_api_url,
      type: 'GET',
      crossDomain: true,
      dataType: 'json',
      success: (result, textStatus, xhr) => {
        try {
          let result_body = result;
          console.log(result_body);
          if(result_body.status === "OK") {
            let _tableDict = tableDict;
            for(let _key in tableDict) {
              let _value = result_body.value[_key];
              if(_value) {
                _tableDict[_key] = _value;
              }
            }
            setTableDict(_tableDict);
            setUpdate(update ? false : true); // 強制再レンダリング
          }
          else {
            console.log("response status NG");
          }
        } catch(error) {
          console.log("response parse error");
        }
      },
      error: (xhr, textStatus, error) => {
        console.log("server error");
      }
    });
  }, [setTableDict]);

  return (
    <HelmetProvider>
      <Helmet
        title={tableDict.detail==LOADING_STR ? "補助金詳細情報｜補助金おすすめ.com" : (tableDict.detail+"（"+tableDict.region+"）｜補助金おすすめ.com")}
        link={[
          {
            rel:"canonical",
            href:(window.location.origin + window.location.pathname)
          }
        ]}
        meta={[
          {
            name:"google-site-verification",
            content:"F_UIiaqRlypk1_5WoPiHdFdneMSpYvOlYLmWBkyXmIU"
          }, {
            name:"description",
            content:(tableDict.abstract==LOADING_STR ? "使える補助金を手軽に知りたい方へ、新着レコメンド！メールアドレスのみで簡単サービス登録できます。補助金をおすすめするための受信設定を行うだけ！完全に無料でご利用いただけます。" : tableDict.abstract)
          }
        ]}
      />
      <div className="text-left hojokin-table mt-3 mx-auto px-2">
        <h1 className="mb-4">
          補助金「{tableDict.detail}」
        </h1>

        <Table responsive>
          <thead>
            <tr>
              <th width="20%">項目</th>
              <th>内容</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>概要</td>
              <td>{tableDict.abstract}</td>
            </tr>
            <tr>
              <td>地域</td>
              <td>{tableDict.region}</td>
            </tr>
            <tr>
              <td>申請期間</td>
              <td>
                {(tableDict.termFrom===LOADING_STR && tableDict.termEnd===LOADING_STR) ? "-" : tableDict.termFrom + "〜" + tableDict.termEnd}
                {(tableDict.termContinue==="True") && (<div>※随時募集</div>)}
                {(tableDict.termBudget==="True") && (<div>※予算なくなり次第終了</div>)}
              </td>
            </tr>
            <tr>
              <td>公式</td>
              <td><a href={tableDict.url} target="_blank" rel="noopener noreferrer">掲載ページはこちら&nbsp;<FontAwesomeIcon icon={['fas', 'external-link-alt']} /></a></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </HelmetProvider>
  );
}

export default HojokinTable;