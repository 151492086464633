import React, { Fragment } from "react";

import RecommendSettingHeader from "../components/RecommendSettingHeader";
import RecommendSettingForm from "../components/RecommendSettingForm";

const RecommendConfig = () => (
  <Fragment>
    <RecommendSettingHeader />
    <RecommendSettingForm />
  </Fragment>
);

export default RecommendConfig;
