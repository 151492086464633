import React, { Component } from "react";
import './RecommendSettingHeader.css';

class RecommendSettingHeader extends Component {
  render() {
    return (
      <div className="next-steps my-5 recommend-setting-header mx-auto px-0">
        <h2 className="my-5 text-center">通知設定</h2>
        <p class="lead text-center pl-0 pr-0">
          補助金・助成金情報をレコメンド通知されるように、<br class="d-none d-lg-inline"/>
          会社の所在地や従業員数などを入力して保存してください。<br class="d-none d-lg-inline"/>
          こちらに入力いただいた内容に基づいて、メール通知いたします。
        </p>
      </div>
    );
  }
}

export default RecommendSettingHeader;
