import React from "react";
import './SolutionDigest.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import issue1 from '../assets/issue1.png';
import issue2 from '../assets/issue2.png';
import solution from '../assets/solution.png';

const SolutionDigest = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <div className="text-center solution-digest mt-3 pb-5">
      {/* トップ */}
      <div className="container">
      <h2 className="mb-0">
        時間がないからといって
        <br class="d-none d-md-inline"/>
        <span class="top-overlook">&nbsp;公募を見落とす&nbsp;</span>
        のはこれで終わり。</h2>
      <p>〜<span class="d-none d-md-inline">おかげさまで、</span>サービス開始から2年で導入660社を突破</p>

      {/* 登録ボタン */}
      {!isAuthenticated && (
        <div class="container rsolution-digest-register p-0 mb-5 mt-0">
          <div>
            <Button
              id="register-button-sd"
              className="btn btn-primary btn-lg mt-3 mb-1 p-3"
              onClick={() => loginWithRedirect({screen_hint: 'signup'})}
            >
              無料でサービス登録
            </Button>
            <Button
              id="contact-button"
              className="btn btn-primary btn-lg mt-3 mb-1 p-3 ml-3"
              href="https://www.localia.co.jp/form"
            >
              お問合せ
            </Button>
            <br/>
            <small className="text-muted mb-5">
              ※&nbsp;
              <a id="privacy-register-buttton-sd" href="https://www.localia.co.jp/privacy" className="text-muted" target="_blank" rel="noopener noreferrer"><u>プライバシーポリシー<FontAwesomeIcon icon={['fas', 'external-link-alt']} /></u></a>
              &nbsp;および&nbsp;
              <a id="service-term-register-buttton-sd" href="https://www.localia.co.jp/terms/hojokin-osusume" className="text-muted" target="_blank" rel="noopener noreferrer"><u>利用規約<FontAwesomeIcon icon={['fas', 'external-link-alt']} /></u></a>
              &nbsp;をご確認のうえ登録を行ってください。
              <br class="d-none d-md-inline"/>
              <span class="d-none d-md-inline">※</span>本サービスは完全無料でご利用いただけます。勝手に費用請求されることはありません。
            </small>
          </div>
        </div>
      )}
      </div>

      <div class="solution-digest-container py-5">
        <div class="container">
          <h2 className="mb-4 pt-2">自社で使える補助金・助成金を新着おすすめ！</h2>
          {/* 課題 */}
          <div class="container solution-digest-issue p-0 mb-5">
            <div class="row">
              <div class="col-sm mb-3">
                <div class="card h-100">
                  <img className="inner-fit" src={issue1} alt="省庁や県市区の公募情報を探しに行くのが大変。通常業務が忙しくて見落としてしまう。" />
                  <div class="card-body">
                    <p class="card-text">省庁や県市区の公募情報を探しに行くのが大変。<br class="d-none d-lg-inline"/>通常業務が忙しくて見落としてしまう。</p>
                  </div>
                </div>
              </div>
              <div class="col-sm mb-3">
                <div class="card h-100">
                  <img className="inner-fit" src={issue2} alt="補助金・助成金の検索サイトはあるけれど、情報が多すぎて結局うまく見つけられない。" />
                  <div class="card-body">
                    <p class="card-text">補助金・助成金の検索サイトはあるけれど、<br class="d-none d-lg-inline"/>情報が多すぎて結局うまく見つけられない。</p>
                  </div>
                </div>
              </div>
            </div>

            {/* 下矢印 */}
            <div class="triangle2bottom mx-auto issue-to-solution mb-3"></div>

            {/* 解決 */}
            <div class="card solution mx-auto">
              <img className="inner-fit" src={solution} alt="最初に会社所在地や従業員数を入力するだけで、あとはメールを受信するだけ！" />
              <div class="card-body">
                <p class="card-text">最初に会社所在地や従業員数を入力するだけで、<br class="d-none d-lg-inline"/>あとはメールを受信するだけ！</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionDigest;
