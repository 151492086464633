import React from "react";
import { Link } from 'react-router-dom';
import "./QuickStart.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import quickstart_steps1 from '../assets/quickstart1.png';
import quickstart_steps2 from '../assets/quickstart2.png';

const QuickStart = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  return (
    <div className="text-center quick-start-container py-5">
      <div class="container">

        {/* さっそく始めましょう */}
        <div class="container quickstart_steps p-0">
          <h2 className="mb-4 mt-3">さっそく始めましょう！</h2>
          <div class="row">
            <div class="col-sm mb-4">
              <div class="card h-100">
                <img className="inner-fit" src={quickstart_steps1} alt="①サービス登録（ユーザー登録）" />
                <div class="card-body">
                  <p class="card-text">&#10102;サービス登録（ユーザー登録）</p>
                </div>
              </div>
            </div>
            <div class="col-sm mb-4">
              <div class="card h-100">
                <img className="inner-fit" src={quickstart_steps2} alt="②補助金おすすめの設定" />
                <div class="card-body">
                  <p class="card-text">&#10103;補助金のおすすめ設定</p>
                </div>
              </div>
            </div>
          </div>
          <p className="lead">
            メールアドレスのみで簡単サービス登録できます。<br class="d-none d-lg-inline"/>
            あとは、補助金をおすすめするための受信設定を行うだけ！<br class="d-none d-lg-inline"/>
            完全に無料でご利用いただけます。
          </p>
        </div>

        {/* 登録ボタン */}
        {!isAuthenticated && (
          <div>
            <Button
              id="register-button"
              className="btn btn-primary btn-lg mt-3 mb-1 p-3"
              onClick={() => loginWithRedirect({screen_hint: 'signup'})}
            >
              無料でサービス登録する
            </Button>
            <br/>
            <small className="text-muted mb-5">
              ※&nbsp;
              <a id="privacy-register-buttton" href="https://www.localia.co.jp/privacy" className="text-muted" target="_blank" rel="noopener noreferrer"><u>プライバシーポリシー<FontAwesomeIcon icon={['fas', 'external-link-alt']} /></u></a>
              &nbsp;および&nbsp;
              <a id="service-term-register-buttton" href="https://www.localia.co.jp/terms/hojokin-osusume" className="text-muted" target="_blank" rel="noopener noreferrer"><u>利用規約<FontAwesomeIcon icon={['fas', 'external-link-alt']} /></u></a>
              &nbsp;をご確認のうえ登録を行ってください。
            </small>
          </div>
        )}

        {/* 通知設定ボタン */}
        {isAuthenticated && (
          <Link
            to="/recommend-config"
            id="register-button"
            className="btn btn-primary btn-lg mt-3 mb-5 p-3"
          >
            補助金のおすすめ設定はこちらから
          </Link>
        )}
      </div>
    </div>
  )
};

export default QuickStart;