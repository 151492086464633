import React from "react";
import "./CanDo.css"

import cando_image1 from '../assets/cando1.png';
import cando_image2 from '../assets/cando2.png';

const CanDo = () => (
  <div className="text-center my-3 pb-4">
    <div className="container">

      {/* 利用のイメージ */}
      <div class="container cando_image p-0">
        <h2 className="mb-4">2分で利用スタート。<br class="d-none d-md-inline"/>あとは待つだけのラクラク運用。</h2>
        <div class="row">
          <div class="col-sm mb-4">
            <div class="card h-100">
              <img className="inner-fit" src={cando_image1} alt="①サービス登録して、補助金情報を受信（メールアドレスや会社所在地を入力）" />
              <div class="card-body">
                <p class="card-text">&#10102;サービス登録して、補助金情報を受信設定<br class="d-none d-lg-inline"/>（メールアドレスや会社所在地を入力）</p>
              </div>
            </div>
          </div>
          <div class="col-sm mb-4">
            <div class="card h-100">
              <img className="inner-fit" src={cando_image2} alt="②おすすめ補助金情報をメールボックス通知→自社で使えないか検討！" />
              <div class="card-body">
                <p class="card-text">&#10103;おすすめ補助金情報をメールボックス通知<br class="d-none d-lg-inline"/>→自社で使えないか検討！</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* リード文 */}
      <p className="lead">
        サービス登録したらメール受信を待つだけ！<br class="d-none d-lg-inline"/>
        受信メールにおすすめ補助金・助成金情報が記載されていますので、<br class="d-none d-lg-inline"/>
        すぐに自社に使えないかご検討いただけます。
      </p>

    </div>
  </div>
);

export default CanDo;